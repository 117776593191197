
import { defineComponent, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import { AxiosRequestConfig } from "axios";
import { toast } from "vue3-toastify";

export default defineComponent({
  name: "request-mod",
  components: {},

  created() {
    document.title = this.$t("request-mod") + " - Gro Repository";
  },

  setup() {
    const formRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);
    const formData = ref({
      title: "",
      author: "",
      description: "",
      link: "",
      screenshotLink: "",
    });

    const rules = ref({
      title: [
        {
          required: true,
          message: "Material name is required",
          trigger: "change",
        },
      ],
      author: [
        {
          required: true,
          message: "Author name is required",
          trigger: "change",
        },
      ],
      link: [
        {
          required: true,
          message: "Link is required",
          trigger: "change",
        },
      ],
      screenshotLink: [
        {
          required: true,
          message: "Screenshot link is required",
          trigger: "change",
        },
      ],
      state: [
        {
          required: true,
          message: "State is required",
          trigger: "change",
        },
      ],
      postCode: [
        {
          required: true,
          message: "Post code is required",
          trigger: "change",
        },
      ],
    });

    const submit = () => {
      if (!formRef.value || loading.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;

          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          /* @ts-ignore */
          ApiService.post("api/mods/send", formData.value)
            .then(() => {
              toast.success("The mod has been sent successfully!");
            })
            .catch((exception) => {
              toast.error("Error when sending mod");
            })
            .finally(() => {
              loading.value = false;
            });
        } else {
          return false;
        }
      });
    };

    return {
      formData,
      rules,
      submit,
      formRef,
      loading,
    };
  },
});
